define("discourse/plugins/hosted-site/discourse/controllers/admin-invite", ["exports", "@ember/controller", "@ember/object", "@ember/object/compat", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/decorators", "discourse/lib/get-url", "discourse/lib/name-validation-helper", "discourse/lib/url", "discourse/lib/waving-hand-url", "discourse/mixins/password-validation", "discourse-i18n"], function (_exports, _controller, _object, _compat, _computed, _ajax, _decorators, _getUrl, _nameValidationHelper, _url, _wavingHandUrl, _passwordValidation, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminInvite extends _controller.default.extend(_passwordValidation.default) {
    maskPassword = true;
    passwordRequired = true;
    redirected = false;
    usernameError;
    lastAttemptedUsername;
    nameValidationHelper = (() => new _nameValidationHelper.default(this))();
    static #_ = (() => dt7948.g(this.prototype, "admin", [(0, _computed.alias)("model.admin")]))();
    #admin = (() => (dt7948.i(this, "admin"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "accountUsername", [(0, _computed.alias)("model.username")]))();
    #accountUsername = (() => (dt7948.i(this, "accountUsername"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "accountName", [(0, _computed.alias)("model.name")]))();
    #accountName = (() => (dt7948.i(this, "accountName"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "submitDisabled", [(0, _computed.or)("passwordValidation.failed", "nameValidation.failed", "usernameErrorMessage")]))();
    #submitDisabled = (() => (dt7948.i(this, "submitDisabled"), void 0))();
    get nameValidation() {
      return this.nameValidationHelper.nameValidation;
    }
    static #_5 = (() => dt7948.n(this.prototype, "nameValidation", [_compat.dependentKeyCompat]))();
    usernameErrorMessage(username, error) {
      if (error && username === this.lastAttemptedUsername) {
        return error;
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "usernameErrorMessage", [(0, _decorators.default)("accountUsername", "usernameError")]))();
    wavingHandURL() {
      return (0, _wavingHandUrl.wavingHandURL)();
    }
    static #_7 = (() => dt7948.n(this.prototype, "wavingHandURL", [(0, _decorators.default)()]))();
    welcomeTitle() {
      return (0, _discourseI18n.i18n)("invites.welcome_to", {
        site_name: this.siteSettings.title
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "welcomeTitle", [_decorators.default]))();
    yourEmailMessage(email) {
      return (0, _discourseI18n.i18n)("admin_invite.your_email", {
        email
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "yourEmailMessage", [(0, _decorators.default)("model.email")]))();
    redirectHref(redirectTo) {
      return (0, _getUrl.default)(redirectTo || "/");
    }
    static #_10 = (() => dt7948.n(this.prototype, "redirectHref", [(0, _decorators.default)("redirectTo")]))();
    togglePasswordMask() {
      this.toggleProperty("maskPassword");
    }
    static #_11 = (() => dt7948.n(this.prototype, "togglePasswordMask", [_object.action]))();
    async submit() {
      this.lastAttemptedUsername = this.accountUsername;
      const result = await (0, _ajax.ajax)({
        url: (0, _url.userPath)(`admin-invite/${this.get("model.token")}.json`),
        type: "PUT",
        data: {
          username: this.accountUsername,
          name: this.accountName,
          password: this.accountPassword,
          timezone: moment.tz.guess()
        }
      });
      if (result.success) {
        this.set("redirectTo", result.redirect_to);
        this.set("redirected", true);
        _url.default.redirectTo(this.redirectHref);
      } else {
        if (result.errors && result.errors["user_password.password"] && result.errors["user_password.password"].length > 0) {
          this.rejectedPasswords.pushObject(this.accountPassword);
          this.rejectedPasswordsMessages.set(this.accountPassword, result.errors["user_password.password"][0]);
        }
        if (result.errors && result.errors.username && result.errors.username.length > 0) {
          this.set("usernameError", result.errors.username[0]);
        }
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "submit", [_object.action]))();
  }
  _exports.default = AdminInvite;
});